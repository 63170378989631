import Footer from '../components/Footer/Footer';
import MarkdownContent from '../components/MarkdownContent/MarkdownContent';

import ConditionsGeneralesUtilisationPath from './md/ConditionsGeneralesUtilisation.md';

const ConditionsGeneralesUtilisation = () => {
  return (
    <div className="page">
      <MarkdownContent path={ConditionsGeneralesUtilisationPath} />
      <Footer />
    </div>
  )
}

export default ConditionsGeneralesUtilisation;


