export const hrefHook = (href, inc = null) => {
  const pattern = /(?:.*-)([0-9]*).(?:x?html)/;
  const currentPage = parseInt(href.match(pattern)[1]);

  // %2 === 0 => left page normally
  const increment = inc || (currentPage % 2 === 0 ? 0 : -1);

  return href.replace(`-${currentPage}.`, `-${currentPage + increment}.`);
}

