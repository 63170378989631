import './Navigation.css';

import React, { useContext } from 'react';

import ThemeContext from '../../contexts/Theme';

const Navigation = ({ prev, next }) => {
  const styles = useContext(ThemeContext);
  return (
    <aside className="Navigation">
      <button onClick={prev} className="prev" style={{ color: styles.textColor }}> ‹ </button>
      <button onClick={next} className="next" style={{ color: styles.textColor }}> › </button>
    </aside>
  );
}

export default Navigation;
