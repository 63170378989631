import './Zoom.css';

import React, { useState, useEffect, useContext } from 'react';

import ThemeContext from '../../contexts/Theme';

import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

const Zoom = ({ zoom }) => {
  const styles = useContext(ThemeContext);

  const [marks, setMarks] = useState(null);
  const [zooms] = useState([100, 125, 150, 175, 200]);

  const mark = (styles) => (zoom) => [zoom, { label: zoom + '%', style: { color: "black" }}]

  useEffect(() => {
    setMarks(
      Object.fromEntries( zooms.map(mark(styles)) )
    )
  }, [styles, zooms])

  return (marks
    ? <div className="Zoom">
        <Slider
          className="Zoom-slider"
          min={zooms[0]}
          max={zooms[zooms.length - 1]}
          defaultValue={zooms[0]}
          marks={marks}
          step={null}
          onChange={(value) => zoom(value + "%")}
          dotStyle={{ borderColor: "black" }}
          activeDotStyle={{ borderColor: "black", backgroundColor: "black" }}
          railStyle={{ backgroundColor: "black" }}
          trackStyle={{ backgroundColor: "black" }}
          handleStyle={{
            borderColor: "black",
            backgroundColor: "black"
          }}
          vertical={true} />
      </div>
    : null
  );
}

export default Zoom;
