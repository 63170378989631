import './App.css';

import Reader from './pages/Reader';
import MentionsLegales from './pages/MentionsLegales';
import ConditionsGeneralesUtilisation from './pages/ConditionsGeneralesUtilisation'
import ProtectionDonneesPersonnelles from './pages/ProtectionDonneesPersonnelles'
import AccessDenied from './pages/AccessDenied';

import ThemeContext, { styles } from './contexts/Theme';

import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route
} from "react-router-dom";

const App = () => {
  return (
    <Router>
      <ThemeContext.Provider value={styles.alf}>
        <div className="App">
          <Switch>
            <Route exact path="/mentions-legales">
              <MentionsLegales />
            </Route>
            <Route exact path="/conditions-generales-utilisation">
              <ConditionsGeneralesUtilisation />
            </Route>
            <Route exact path="/protection-des-donnees-personnelles">
              <ProtectionDonneesPersonnelles />
            </Route>
            <Route exact path="/">
              <Reader />
            </Route>
            <Route path="/access-denied">
              <AccessDenied />
            </Route>
            <Route path="*">
              <Redirect to="/access-denied" />
            </Route>
          </Switch>
        </div>
      </ThemeContext.Provider>
    </Router>
  );
}

export default App;

