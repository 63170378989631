const AccessDenied = () => {
  return (
    <div className="access-denied">
      <h1>Vous avez été déconnecté, veuillez vous reconnecter via votre ENT</h1>
    </div>
  )
}

export default AccessDenied;


