import './EmbedMedia.css';

import ReactPlayer from 'react-player/file'
import Modal from 'react-modal';

// http://reactcommunity.org/react-modal/
Modal.setAppElement('#root');

const EmbedMedia = ({media, thumbnail, type, close, styles}) => {
  const modalStyles = (!!thumbnail && type === 'embed-audio') ? {
    content: {
      backgroundImage: `url(${thumbnail})`
    }
  } : {};

  return (
    <Modal
      isOpen={true}
      style={modalStyles}
      className={`EmbedMedia-modal ${type}`}
      overlayClassName="EmbedMedia-overlay"
      onRequestClose={close}
      shouldCloseOnOverlayClick={true}
      contentLabel="EmbedMedia">
        <button
          className="EmbedMedia-close"
          onClick={() => close()}>&times;
        </button>
        <ReactPlayer
          url={media}
          controls={true}
          light={thumbnail && type === 'embed-video'}
          pip={true}
          width='100%'
          height='100%'/>
    </Modal>
  )
}

export default EmbedMedia;
