import term from '../term.png';

import React, { useState, useEffect } from 'react';

import EbookReader from '../components/EbookReader/EbookReader';

import { getSignedUrl } from "@aws-sdk/s3-request-presigner";
import { S3Client, GetObjectCommand } from "@aws-sdk/client-s3";
import { Link, useLocation, useHistory } from "react-router-dom";
import localForage from "localforage";
import jwt_decode from "jwt-decode";
import useOnlineStatus from '@rehooks/online-status';

const bucket = "garninja-ebooks";
const client = new S3Client({
  apiVersion: '2006-03-01',
  region: process.env.REACT_APP_AWS_REGION,
  credentials: {
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY
  }
});

// https://reactrouter.com/web/example/query-parameters
const useQuery = () => new URLSearchParams(useLocation().search);

class ReaderError extends Error {}

const Reader = () => {
  const history = useHistory();
  const queryParameters = useQuery();
  const onlineStatus = useOnlineStatus();

  const [userId, setUserId] = useState(null);
  const [token] = useState(queryParameters.get('token'));
  const [book] = useState(queryParameters.get('book'));
  const [url, setUrl] = useState(null);

  useEffect(() => {
    const fetchEbook = async () => {
      // https://docs.aws.amazon.com/AWSJavaScriptSDK/latest/AWS/S3.html#getObject-property
      // https://stackoverflow.com/a/64655864
      const command = new GetObjectCommand({
        Bucket: bucket,
        Key: book,
        ResponseCacheControl: "max-age=31536000",
        ResponseContentType: "application/epub+zip",
        ResponseContentDisposition: "inline"
      });
      try {
        if (token) {
          const user = jwt_decode(token);
          setUserId(user.gar_ido);

          const endpoint = process.env.REACT_APP_BACKEND_ENDPOINT;
          const response = await fetch(endpoint, {
            headers: new Headers({
             'Authorization': `Bearer ${token}`,
            })
          })
          const data = await response.json();
          const current_ido = await localForage.getItem('gar_ido');
          if(user.gar_ido !== current_ido) {
            const store = localForage.createInstance({
              name: "bookmarks"
            });
            await store.clear();
          }
          await localForage.setItem('book', data.book_url);
          await localForage.setItem('token', token);
          await localForage.setItem('gar_ido', user.gar_ido);
          setUrl(data.book_url);
        } else if (book) {
          const url = await getSignedUrl(client, command, { expiresIn: 5 * 60 });
          setUrl(url);
        } else {
          throw new ReaderError("Missing 'token' or 'ebook' parameter");
        }
      } catch (err) {
        if (token && !onlineStatus) {
          const usedToken = await localForage.getItem('token');
          if (usedToken === token) {
            const url = await localForage.getItem('book');
            if (!!url) {
              const userId = await localForage.getItem('gar_ido');
              setUserId(userId);

              setUrl(url);
              return;
            }
          }
        }
        console.error(err);
        await localForage.clear();
        history.push('/access-denied');
      }
    };
    fetchEbook();
  }, [book, token, onlineStatus, history])

  return (
    <>
      <header className="App-Header">
      </header>

      <main>
        { url && <EbookReader book={url} userId={userId} /> }
      </main>

      { url && <footer className="App-Footer">
        <Link to="/protection-des-donnees-personnelles" target="_blank">
          <img src={term} className="App-term" alt="Terms"/>
        </Link>
      </footer>
      }
    </>
  )
}

export default Reader;


