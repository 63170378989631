import Footer from '../components/Footer/Footer';
import MarkdownContent from '../components/MarkdownContent/MarkdownContent';

import ProtectionDonneesPersonnellesPath from './md/ProtectionDonneesPersonnelles.md';

const ProtectionDonneesPersonnelles = () => {
  return (
    <div className="page">
      <MarkdownContent path={ProtectionDonneesPersonnellesPath} />
      <Footer />
    </div>
  )
}

export default ProtectionDonneesPersonnelles;


