import Footer from '../components/Footer/Footer';
import MarkdownContent from '../components/MarkdownContent/MarkdownContent';

import MentionsLegalesPath from './md/MentionsLegales.md';

const MentionsLegales = () => {
  return (
    <div className="page">
      <MarkdownContent path={MentionsLegalesPath} />
      <Footer />
    </div>
  )
}

export default MentionsLegales;


