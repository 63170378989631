import reader from '../../images/reader.png';
import './LoadingView.css';

import React, { useContext } from 'react';

import ThemeContext from '../../contexts/Theme';

const LoadingView = () => {
  const styles = useContext(ThemeContext);

  return (
    <div className="LoadingView">
      <div className="LoadingView-background"
          style={{ backgroundColor: styles.backgroundColor }}>

        <img src={reader} className="LoadingView-reader" alt="reader" />

        <p className="LoadingView-loading">
          Chargement en cours ...
        </p>
      </div>
    </div>
  )
}

export default LoadingView;
