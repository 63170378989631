import { ReactComponent as HamburgerButton } from '../../images/menu.svg';
import './Hamburger.css';

import React, { useContext } from 'react';

import ThemeContext from '../../contexts/Theme';

const Hamburger = ({ toggleMenu }) => {
  const styles = useContext(ThemeContext);

  return (
    <button
      type="button"
      onClick={toggleMenu}
      className="Hamburger"
      style={{ backgroundColor: styles.backgroundColor, color: styles.textColor }}>
      <HamburgerButton fill={ styles.textColor } />
      Menu
    </button>
  );
}

export default Hamburger;
