import './TocContent.css';

import bookmarkDefault from '../../images/bookmark-default.png';
import bookmarkActive from '../../images/bookmark-selected.png';

import React, { useState, useEffect, useContext } from 'react';

import ThemeContext from '../../contexts/Theme';

import { hrefHook } from '../../utils/helpers';

import { push as Menu } from 'react-burger-menu'

const TocLink = ({ link, onChangePage, onDeleteBookmark, level = 0 }) => {
  const isBookmark = (link.type && link.type === 'bookmark');
  let sublinks = []

  if (!isBookmark && link.subitems) {
    sublinks = link.subitems.map((anotherLink) => {
      // Fix issue where link was corresponding to right page - Left page was blank
      // So we cheat by changing right page to left page
      return (
        <TocLink
          key={anotherLink.id}
          link={anotherLink}
          onChangePage={() => onChangePage(hrefHook(anotherLink.href))}
          level={level + 1} />
      )
    })
  }

  return (
    <div className={'level-' + level}>
      <div className="TocLink-row">
        <button className="TocLink-link" onClick={() => onChangePage(link.href)}>
          { link.label }
        </button>
        { isBookmark && <button className="TocLink-link" onClick={() => onDeleteBookmark(link.href)}>
            &times;
          </button>
        }
      </div>

      { (sublinks && level < 1) && <div className="TocLink-subchapters">
          { sublinks }
      </div>}
    </div>
  )
}

const TocContent = ({
  chapters, bookmarks, menuOpen, setMenuOpen, setPage, onDeleteBookmark
}) => {
  const styles = useContext(ThemeContext);
  const [menuTab, setMenuTab] = useState("toc");

  const closeMenuAndChangePage = (href) => {
    setMenuOpen(false);
    setPage(href);
  }

  const renderLinks = (items) => {
    return items.map((item) => {
      return (
        <TocLink
          key={item.id}
          link={item}
          onChangePage={closeMenuAndChangePage}
          onDeleteBookmark={onDeleteBookmark}
          styles={styles} />
      );
    });
  }

  const tocLinks = renderLinks(chapters);
  const tocBookmarks = renderLinks(bookmarks);

  const menuStyles = {
    bmMenu: {
      backgroundColor: styles.menuBackgroundColor
    },
    bmCross: {
      backgroundColor: styles.textColor
    }
  }

  useEffect(() => {
    if (menuTab === 'bookmarks' && bookmarks.length === 0) {
      setMenuTab('toc');
    }
  }, [bookmarks, menuTab]);

  return (
    <div className="TocContent">
      <Menu
        isOpen={ menuOpen }
        onStateChange={(state) => setMenuOpen(state.isOpen)}
        pageWrapId={ "page-wrap" }
        outerContainerId={ "outer-container" }
        width={ '250px' }
        styles={ menuStyles }>
        <div>
          <header className="TocContent-header">
            <button className="TocContent-summary"
              onClick={() => setMenuTab('toc')}
              style={{ color: menuTab === 'toc' ? styles.textColor : styles.inactiveColor }}>
              Sommaire
            </button>
            { !!bookmarks.length && <button
                className="TocContent-bookmarks"
                onClick={() => setMenuTab('bookmarks')}>
              { menuTab === 'toc' && <img src={bookmarkActive} alt="Bookmark not active"/>}
              { menuTab === 'bookmarks' && <img src={bookmarkDefault} alt="Bookmark active"/>}
            </button>}
          </header>

          { menuTab === 'toc' && tocLinks }
          { (menuTab === 'bookmarks' && bookmarks.length !== 0) && tocBookmarks }
        </div>
      </Menu>
    </div>
  );
}

export default TocContent;
