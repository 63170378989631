import { ReactComponent as BookmarkSaved } from '../../images/bookmark-saved.svg';
import { ReactComponent as BookmarkUnsaved } from '../../images/bookmark-unsaved.svg';

import './BookmarkPage.css';

import React, { useEffect } from 'react';

import ReactTooltip from 'react-tooltip';

let tooltipRef = React.createRef();

const BookmarkPage = ({ isFirstPageRendered, refreshToolTip, isSaved, toggleBookmark }) => {

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [refreshToolTip])

  useEffect(() => {
    isFirstPageRendered
      ? ReactTooltip.show(tooltipRef)
      : ReactTooltip.hide(tooltipRef)
  }, [isFirstPageRendered])

  return (
    <React.Fragment>
      <ReactTooltip
        effect="solid"
        place="bottom"
        type="dark"
      />

      <button
        ref={ref => tooltipRef = ref}
        data-tip={ isSaved ? "Supprimer ce signet" : "Ajouter un signet sur cette page" }
        onClick={toggleBookmark}
        className="BookmarkPage"> {
          isSaved
          ? <BookmarkSaved />
          : <BookmarkUnsaved />
        }
      </button>
    </React.Fragment>
  );
}

export default BookmarkPage;
