import './EbookReader.css';

import BookmarkPage from '../BookmarkPage/BookmarkPage';
import EmbedMedia from '../EmbedMedia/EmbedMedia';
import Hamburger from '../Hamburger/Hamburger';
import LoadingView from '../LoadingView/LoadingView';
import Navigation from '../Navigation/Navigation';
import TocContent from '../TocContent/TocContent';
import Zoom from '../Zoom/Zoom';

import { hrefHook } from '../../utils/helpers';

import React, { useState, useEffect } from 'react';

import {
  EpubView // Underlaying epub-canvas (wrapper for epub.js iframe)
} from "react-reader";
import localForage from "localforage";

const store = localForage.createInstance({
  name: "bookmarks"
});

const readerRef = React.createRef();

// https://github.com/gerhardsletten/react-reader
const EbookReader = ({ book, userId }) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const [page, setPage] = useState(0);
  const [firstPageRendered, setFirstPageRendered] = useState(false);
  const [shouldRefreshTooltip, setRefreshTooltip] = useState(Date.now());
  const [pages, setPages] = useState(null);
  const [chapters, setChapters] = useState([]);
  const [bookmarks, setBookmarks] = useState([]);

  // https://github.com/futurepress/epub.js/blob/master/types/rendition.d.ts
  const [rendition, setRendition] = useState(null);
  const [embedMedia, setEmbedMedia] = useState(null);

  useEffect(() => {
    setRefreshTooltip(Date.now());
  }, [page, menuOpen])

  // TODO useMemo ?
  useEffect(() => {
    setPages(
      chapters.flatMap((chapter) => {
        const filterAttrs = ({ id, href, label }) => ({id, href, label})
        const subitems = chapter.subitems.map(filterAttrs);
        return [filterAttrs(chapter)].concat(subitems);
      })
    )
  }, [chapters]);

  useEffect(() => {
    // FIXME avoid setting it multiple times

    if (rendition) {
      const onClick = (ev) => {
        const element = ev.target.closest(".btn-media");
        if (!element) return
        const type = element.getAttribute("data-type");
        const href = element.getAttribute("data-href");
        const thumbnail = element.getAttribute("data-thumbnail") || false;
        if (['embed-video', 'embed-audio'].includes(type)) {
          setEmbedMedia({ href, thumbnail, type });
        }
      }
      rendition.on("click", onClick);
    }
  }, [rendition]);

  useEffect(() => {
    (async () => {
      const bookmarks = await store.getItem(userId);
      setBookmarks(bookmarks || []);
    })()
  }, [userId]);

  useEffect(() => {
    if (bookmarks.length > 0) {
      (async () => await store.setItem(userId, bookmarks))()
    }
  }, [bookmarks, userId])

  /* Navigation between pages */
  const prev = () => {
    const node = readerRef.current;
    node.prevPage();
  };

  const onLocationChange = (page) => {
    setPage(current => {
      setFirstPageRendered(current === null);
      return page;
    })
  }

  const next = () => {
    const node = readerRef.current;
    node.nextPage();
  };

  /* Bookmarks */
  // https://github.com/futurepress/epubjs-reader/blob/master/reader/js/reader.js#L3554-L3561
  const generateBookmark = (book, page) => {
    const spineItem = book.spine.get(page);
    const bookmark = {
      id: spineItem.idref,
      href: page,
      type: 'bookmark'
    };

    let ref = pages.find(page => page.href === spineItem.href);
    if (!ref) {
      const isLeftPage = spineItem.properties.includes("page-spread-left");
      ref = pages.find(page => page.href === hrefHook(spineItem.href, isLeftPage ? 1 : -1))
    }

    if (ref) {
      bookmark.label = ref.label;
    } else {
      bookmark.label = `Page ${spineItem.index + 1}/${spineItem.index + 2}`;
    }

    return bookmark;
  }

  const isSavedBookmark = (currentBookmarks, page) => {
    return currentBookmarks.findIndex(bookmark => bookmark.href === page) !== -1
  }

  const addBookmark = (book, currentBookmarks, page) => {
    const bookmark = generateBookmark(book, page);
    return currentBookmarks.concat([bookmark]);
  }

  const deleteBookmark = (currentBookmarks, page) => {
    return currentBookmarks.filter(bookmark => bookmark.href !== page);
  }

  const toggleBookmark = (currentPage) => {
    setBookmarks((currentBookmarks) => {
      return isSavedBookmark(currentBookmarks, currentPage)
        ? deleteBookmark(currentBookmarks, currentPage)
        : addBookmark(rendition.book, currentBookmarks, currentPage);
    })
  }

  return (
    <div id="outer-container" className="EbookReader">
      { page && <Navigation prev={prev} next={next} /> }

      { (page && embedMedia) && <EmbedMedia media={embedMedia.href} thumbnail={embedMedia.thumbnail} type={embedMedia.type} close={() => setEmbedMedia(null) } /> }

      { page && <Zoom zoom={(percentage) => rendition.resize(percentage, percentage)} /> }

      { page && <Hamburger toggleMenu={() => setMenuOpen(!menuOpen)} /> }

      { page && <TocContent
          chapters={chapters}
          bookmarks={bookmarks}
          menuOpen={menuOpen}
          setMenuOpen={setMenuOpen}
          setPage={onLocationChange}
          onDeleteBookmark={toggleBookmark}
        />
      }

      { (page && !menuOpen) && <BookmarkPage
          isSaved={isSavedBookmark(bookmarks, page)}
          toggleBookmark={() => toggleBookmark(page)}
          isFirstPageRendered={firstPageRendered}
          refreshToolTip={shouldRefreshTooltip}
        />
      }

      <article id="page-wrap" style={{ position: "relative", height: "100%" }}>
        <EpubView
          ref={readerRef}
          url={book}
          location={page}
          locationChanged={epubcifi => onLocationChange(epubcifi) }
          tocChanged={chapters => setChapters(chapters) }
          getRendition={rendition => setRendition(rendition)}
          epubInitOptions={{
            openAs: 'epub',
            store: "epubjs-books",
            requestHeaders: {
              "Cache-Control": "public, max-age=31536000"
            }
          }}
          loadingView={<LoadingView />}
        />
      </article>
    </div>
  );
}

export default EbookReader;
