import React, { useState, useEffect } from 'react';

import ReactMarkdown from 'react-markdown'

const MarkdownContent = ({ path }) => {
  const [content, setContent] = useState("");

  useEffect(() => {
    const fetchMarkdown = async () => {
      const response = await fetch(path);
      const content = await response.text();
      setContent(content);
    };
    fetchMarkdown();
  }, [path])

  return (
    <article>
      { content && <ReactMarkdown source={content} /> }
    </article>
  )
}

export default MarkdownContent;


