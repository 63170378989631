import React from 'react';

const styles = {
  default: {
    backgroundColor: "#CFE7CD",
    textColor: "#333",
    inactiveColor: "rgba(84, 84, 84, 0.16)",
    menuBackgroundColor: "#CFE7CD"
  },
  alf: {
    backgroundColor: "#FEEB33",
    textColor: "#196471",
    inactiveColor: "rgba(84, 84, 84, 0.16)",
    menuBackgroundColor: "#FFFFFF"
  }
}

const ThemeContext = React.createContext({
  ...styles.default
});
ThemeContext.displayName = 'ThemeContext'; // mainly for DevTools

export default ThemeContext;
export { styles }
