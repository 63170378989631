import { Link } from "react-router-dom";

import './Footer.css'

const Footer = () => {
  return (
    <footer>
      <ul className="links">
        <li>
          <Link to="/mentions-legales">Mentions légales</Link>
        </li>
        <li>
          <Link to="/conditions-generales-utilisation">Conditions générales d'utilisation</Link>
        </li>
        <li>
          <Link to="/protection-des-donnees-personnelles">Protection des données personnelles </Link>
        </li>
      </ul>
    </footer>
  )
}

export default Footer;


